export const URLS = {
    Register: "authentication/register",
    Address: "address/addressdetails",
    Policy : "",
    Beneficiary: "beneficiary/beneficiary",
    AddDependent: "dependent/adddependent",
    Banking: "banking/bankingdetails",
    Premium: "premium/submitpremiumdetails",
    PremiumAccepted: "premium/acceptpremium",
    searchChampionPerRegion: "customer/getChampionChurch",
    paymentMethod: "premium/selectPaymentMethod",
    getCustomer: "customer",
    quizzesByEnrollment: "Quiz/GetQuizzesWithEnrollment",
    quizEnrollment: "Quiz/quizEnrollment"
}