import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styles from './Quiz.module.scss';
import { Container } from '@mui/material';
import Loader from "../../components/Shared/Loader";
import SharedButton from '../../components/Shared/SharedButton/SharedButton';


const Quiz = () => {

  const { quizId } = useParams();
  const axios = require("axios").default;
  const [loader, setLoader] = useState(false);
  const [quiz, setQuiz] = useState({
  });
  const [quizLoaded, setQuizLoaded] = useState(false);
  const [activeQuestion, setActiveQuestion] = React.useState(0);
  const [activeOptions, setActiveOptions] = React.useState([]);
  const [selectedAnswer, setSelectedAnswer] = React.useState('');
  const [showResult, setShowResult] = React.useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = React.useState(null);
  const [result, setResult] = React.useState({
    score: 0,
    passed: false,
    correctAnswers: 0,
    wrongAnswers: 0,
  });
  const [quizTitle, setQuizTitle] = React.useState('');
  const [questionText, setQuestionText] = React.useState('');
  const [questionCount, setQuestionCount] = React.useState();
  const token = localStorage.getItem("token");

  let navigate = useNavigate();

  // const currentQuestion = quiz;
  // const { questionId, questionHeading, options } = currentQuestion[activeQuestion];

  const setActiveQuestionLocalStorage = (index) => { 
    localStorage.setItem("activeQuestion" , index);
  }

  const getActiveQuestionLocalStorage = () => { 
    let activeQuestion = JSON.parse(localStorage.getItem("activeQuestion"));
    console.log(activeQuestion);
    return activeQuestion;
  }

  const onClickNext = async () => {
    setSelectedAnswerIndex(null);
    await recordQuizAnswer();

    if (activeQuestion !== questionCount - 1) {
      setActiveQuestion((prev) => {
       let activeQuestion = prev + 1;
        setActiveQuestionLocalStorage(activeQuestion);
        return activeQuestion;
      });
      
    } else {
      calculateScore();
    }
  };

  const calculateScore = async () => {
    setLoader(true);
    try {
      const response = await axios.post(process.env.REACT_APP_URL + `Quiz/quizScore`, {
        "quizId": quizId,
      }, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (response.status == 200) {
        setActiveQuestion(0);
        setActiveQuestionLocalStorage(0);
        let resp = response.data;
        console.log("resp.passed : " , resp.passed)
        setResult({ passed: resp.passed, score: resp.score })
        setLoader(false);
        setShowResult(true);
      }
    } catch (error) {
      console.error("Error:", error);
      // console.log(error);

      // props.showSnackbar("Failed, please try again", "error");
      setLoader(false);
    }
  }

  const onAnswerSelected = (answer, index) => {
    setSelectedAnswerIndex(index);
    // if (answer === quiz[activeQuestion].correctAnswer) {
    setSelectedAnswer(answer.id);
    console.log("answerId : ");
    // do request to server to record quizAttempt

    // } else {
    //   setSelectedAnswer(false);
  };

  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  const fetchQuizwithQuizId = async (quizId) => {
    // setQuizLoaded(true);

    try {
      const response = await axios.get(process.env.REACT_APP_URL + `Quiz/${quizId}/questions`, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (response.status == 200) {
        console.log("reponse : ", response.status);
        const { data } = response;
        console.log("quiz data : ", data);
        setQuestionCount(data.length);
        setQuiz(data);
        setQuizLoaded(true);
       setActiveQuestion(getActiveQuestionLocalStorage());
        // setActiveQuestion(getActiveQuestionLocalStorage());
      }

    } catch (error) {
      console.error("Error:", error);
      // console.log(error);

      // props.showSnackbar("Failed, please try again", "error");
      setLoader(false);
    }
  };

  if (quiz != undefined) {

  }

  React.useEffect(() => {
    fetchQuizwithQuizId(quizId);
  }, []);

  const recordQuizAnswer = async () => {
    setLoader(true);
    try {
      const response = await axios.post(process.env.REACT_APP_URL + `Quiz/recordanswer`, {
        "quizId": quizId,
        "questionId": quiz[activeQuestion].questionId,
        "selectedAnswerId": selectedAnswer
      }, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (response.status == 200) {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      // console.log(error);

      // props.showSnackbar("Failed, please try again", "error");
      setLoader(false);
    }
  }

  const routeChange = (path) => {
    navigate(path);
  };

  let quizContent = (
    <>
      <div className={styles["quiz-container"]}>
        {!showResult && quiz && quiz.length > 0 && (
          <div>
            <div>
              <span className={styles["active-question-no"]}>
                {/* {addLeadingZero(activeQuestion + 1)} */}
                {addLeadingZero(activeQuestion + 1)}
              </span>
              <span className={styles["total-question"]}>
                /{addLeadingZero(quiz.length)}
              </span>
            </div>

            <h2>{quiz[activeQuestion].questionHeading}</h2>
            <ul>
              {quiz[activeQuestion].options.map((answer, index) => (
                <li
                  onClick={() => onAnswerSelected(answer, index)}
                  key={answer.id}
                  className={`${selectedAnswerIndex === index ? styles['selected-answer'] : ''}`}
                >
                  {answer.text}
                </li>
              ))}
            </ul>
            <div className={styles["flex-right"]}>
              <button
                onClick={onClickNext}
                disabled={selectedAnswerIndex === null}
              >
                {activeQuestion === questionCount - 1 ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )

  let calculatedScore = (
    <>
          <div className={styles["result"]}>
            <h1>Result</h1>
            <h2>{result.passed ? "Congratulations! you have passed the Test!" : "Please try again" }</h2>
            <p>
              Total Score:<span> { result.score.toFixed(0) } % </span>
            </p>
            <p>
              Status :<span> { result.passed ? "Passed" : "Failed" }</span>
            </p>
            <p>
            { result.passed ? "Please check you inbox to receive your certificate of completion."  : ""}
            </p>

           {result.passed ? 
            <SharedButton
           
                 variant="contained"
                 text={`Back to Quiz page`}
                 handleOnClick={() => {routeChange("/dashboard/quiz")}}
               ></SharedButton> : <SharedButton
           
               variant="contained"
               text={`Back to Quiz page`}
               handleOnClick={() => {routeChange("/dashboard/quiz")}}
             ></SharedButton>} 
          </div>
    </>
  )

  return (
    <>
    <Loader state={loader} ></Loader> 
      {quizLoaded ?
        <Container maxWidth="md">
          {showResult ? calculatedScore : quizContent}
        </Container>
        : <Loader state={!quizLoaded} ></Loader>
      }
    </>
  )
}

export default Quiz