import React from 'react'
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles["footer-container"]}>
      <div className={styles["footer-bar"]}>
        <div className={styles["footer-content-container"]}>
          <div className={styles["footer-content"]}>
          Copyright © 2011–2023 TRIARC (Pty) Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer