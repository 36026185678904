import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import QuizCard from './QuizCards/QuizCard';
import { URLS } from "../../src/components/Shared/Routes/Routes";
import React, { useState, useEffect, useContext } from 'react';
import Loader from "../components/Shared/Loader";
import AuthContext from "../components/Context/AuthContext"

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function BlogPage() {

  const [quizes, setQuizes] = useState([]);
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);
  const axios = require("axios").default;
  const authContext = useContext(AuthContext);


  useEffect(() => {
    GetQuizNames();
    console.log("quizes : ", quizes);
  }, [])

  const GetQuizNames = async () => {
    setLoader(true);
    try {
      const response = await axios.get(process.env.REACT_APP_URL + URLS.quizzesByEnrollment, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      if (response.status == 200) {
        setLoader(false);
        const data = response.data;
        setQuizes(data);
      }
    } catch (error) {
      console.error("Error:", error);
      console.log(error);

      // props.showSnackbar("Failed, please try again", "error");
      setLoader(false);
    }

  }



  return (
    <>
      <Helmet>
        <title>Dashboard: Quiz</title>
      </Helmet>

      <Container>
      <Loader state={loader} ></Loader>
        <Stack direction="column" alignItems="" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Empowering Your Success with Tests
          </Typography>
          {/* <Typography variant="body2" gutterBottom>
          Passing a test is a moment of triumph, and we want to share in that joy with you. Our notification system ensures that your accomplishments never go unnoticed.
          </Typography> */}
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button> */}
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          {/* <BlogPostsSearch posts={POSTS} /> */}
          {/* <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>

        <Grid container spacing={3} gap={3} justifyContent="center">
          {quizes.filter(x => x.category == authContext.inputs.brokerCategory).map((x, index )=> (
            <>
              <QuizCard title={x.title} quizId={x.id} attempts={x.quizEnrollment} passed={x.passed} imageIndex={index}></QuizCard>
            </>
          ))}
        </Grid>
      </Container>
    </>
  );
}
