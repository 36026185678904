import React, { useState, useContext } from 'react'
// import catholicTrainingGuide from 's3://brokerdocs/Catholic_Church_Training_Guide.pdf';
import DownloadCards from './DownloadCards/DownloadCards';
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import Loader from "../../components/Shared/Loader";
import AuthContext from "../../components/Context/AuthContext";

const Downloads = () => {
    // const [catholic1, setCatholic1] = useState(catholicTrainingGuide);
    const [loader, setLoader] = useState(false);
    const authContext = useContext(AuthContext);

    console.log("inputs : ", authContext.inputs.brokerType);

    return (
        <>
            <Container>
                <Loader state={loader} ></Loader>
                <Stack direction="column" alignItems="" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Downloadable training guides
                    </Typography>
                    {/* <Typography variant="body2" gutterBottom>
                        Access our downloadable training guides and gain valuable insights, tips, and knowledge to excel in the dynamic world of brokerage.
                    </Typography> */}
                    {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button> */}
                </Stack>
                <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                    {authContext.inputs.brokerType == 0 &&
                        <>
                            <DownloadCards heading={"Catholic Church training guide"} url={'https://triarcdocs.s3.eu-west-1.amazonaws.com/portaldocs/Catholic_Church_Training_Guide.pdf'} body={"Catholic church training guide for Champions"} fileName={"Catholic Church training guide"} />
                        </>
                    }

                    {authContext.inputs.brokerType == 1 &&
                        <>
                            <DownloadCards heading={"Group risk products"} url={'https://triarcdocs.s3.eu-west-1.amazonaws.com/portaldocs/EB%2BTraining%2BExisting%2BBrokers%2B2024.pdf'} body={"Group risk products"} fileName={"Group risk products guide"} />
                        </>
                    }

                    {authContext.inputs.brokerType == 2 &&
                        <>
                            <DownloadCards heading={"Starter Funeral Benefit Policy"} url={'https://triarcdocs.s3.eu-west-1.amazonaws.com/portaldocs/Triarc+Retail+Funeral+Starter+Product+Training+2024.pdf'} body={"Starter Funeral Benefit Policy"} fileName={"Starter Funeral Benefit Policy"} />
                        </>
                    }

                    {authContext.inputs.brokerType == 2 &&
                        <>
                            <DownloadCards heading={"My Last Wish Funeral Benefit Policy"} url={'https://triarcdocs.s3.eu-west-1.amazonaws.com/portaldocs/Triarc+Retail+My+Last+Wish+Product+Training+2024.pdf'} body={"My Last Wish Funeral Benefit Policy"} fileName={"My Last Wish Funeral Benefit Policy"} />
                        </>
                    }
                </Container>
            </Container>
        </>
    )
}

export default Downloads