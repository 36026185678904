import React, { useState, useEffect, useRef, useContext } from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import DashboardAppPage from '../../Pages/DashboardAppPage';

const Home = () => {

  return (
    <>
      <CssBaseline />
      <DashboardAppPage />
    </>
  )
}

export default Home
