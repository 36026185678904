import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Loader from "../Shared/Loader";
import AuthContext from "../Context/AuthContext";
import theme from "../Shared/theme";
import nationalIdNumber from '../Shared/IdNumberValidator';
import { emailRegex, passwordRegex } from '../Shared/Regex/regex';
import { URLS } from "../Shared/Routes/Routes";
import SharedButton from '../Shared/SharedButton/SharedButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const Register = (props) => {

  const [loader, setLoader] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    idNumber: "",
    email: "",
    password: "",
    brokerName: "",
    fspNumber: "",
    brokerType: "",
    brokerCategory: ""
  });

  const [errors, setError] = useState({
    name: false,
    surname: false,
    idNumber: false,
    password: false,
    email: false,
    brokerName: false,
    fspNumber: false,
    brokerType: false
  });

  const [valid, setValid] = useState({
    name: false,
    surname: false,
    idNumber: false,
    password: false,
    email: false,
    brokerName: false,
    fspNumber: false,
    brokerType: false
  });

  const [open, setOpen] = React.useState(false);

  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  const handleClose = () => {
    setOpen(false);
    routeChange("/");
  };

  const authContext = useContext(AuthContext);

  // generic handler
  const InputChangeHandler = (event) => {
    const value = event.target.value;
    let idNumber = event.target.value;

    setInputs({
      ...inputs,
      [event.target.name]: value,
    });

    if (event.target.value === "") {
      setError({ ...errors, [event.target.name]: true });
      setValid({ ...valid, [event.target.name]: false });
      return;
    }

    if (event.target.name.trim() === "idNumber") {
      setError({ ...errors, [event.target.name]: true });
      const trimmedIdNumber = idNumber.replace(/\s/g, '');
      if (nationalIdNumber({ number: trimmedIdNumber, minAge: 18 })) {
        setInputs({ ...inputs, [event.target.name]: trimmedIdNumber });
        setError({ ...errors, [event.target.name]: false });
        setValid({ ...valid, [event.target.name]: true });
        return;
      }
      return;
    }

    if (event.target.name.trim() == "email") {
      setError({ ...errors, [event.target.name]: true });

      if (emailRegex.test(event.target.value.trim())) {
        setError({ ...errors, [event.target.name]: false });
        setValid({ ...valid, [event.target.name]: true });
        return;
      }
      return;
    }

    if (event.target.name.trim() == "password") {
      setError({ ...errors, [event.target.name]: true })

      if (passwordRegex.test(event.target.value.trim())) {
        setError({ ...errors, [event.target.name]: false });
        setValid({ ...valid, [event.target.name]: true });
        return;
      }
      return;
    }

    setValid({ ...valid, [event.target.name]: true });
    setError({ ...errors, [event.target.name]: false });
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    if (inputs.name === "") {
      setError((x) => ({ ...x, name: true }));
    }

    if (inputs.surname === "") {
      setError((x) => ({ ...x, surname: true }));
    }

    if (inputs.idNumber === "") {
      setError((x) => ({ ...x, idNumber: true }));
    }

    if (inputs.password === "") {
      setError((x) => ({ ...x, password: true }));
    }

    if (inputs.email === "") {
      setError((x) => ({ ...x, email: true }));
    }

    if (inputs.brokerName === "") {
      setError((x) => ({ ...x, brokerName: true }));
    }

    if (inputs.fspNumber === "") {
      setError((x) => ({ ...x, fspNumber: true }));
    }

    if (inputs.brokerType === "") {
      setError((x) => ({ ...x, brokerType: true }));
    }

    if (
      valid.name &&
      valid.surname &&
      valid.idNumber &&
      valid.password &&
      valid.email &&
      valid.brokerName &&
      valid.fspNumber &&
      valid.brokerType
    ) {
      sendPostRequest();
    } else {
      props.showSnackbar("Please enter all fields", "error");
    }
  };

  const axios = require("axios").default;

  const { name, surname, idNumber, email, brokerName, fspNumber, password, brokerType } = inputs;
  
  // fix everything very messy

  let brokerCategory = (brokerType == 0 || brokerType == 2) ? 0 : 1;

  const sendPostRequest = async (event) => {

    try {
      setLoader(true);
      const response = await axios.post(
        process.env.REACT_APP_URL + URLS.Register,
        { name, surname, IdNumber: idNumber, email, brokerName, BrokerFSPNumber: fspNumber, password, brokerType, brokerCategory }
      ).catch(() => {
        setLoader(false);
      });

      if (response.status == 200) {
        props.showSnackbar("Successful waiting on confirmation", "success");
        setLoader(false);
        setOpen(true);
      }
    } catch (err) {
      props.showSnackbar("Registration Failed please try again", "error");
    }
  };


  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 style={{ margin: '0' }}>
              Financial advisor Details
            </h1>
            <h3 style={{ margin: '0' }}>
              Please enter your details
            </h3>
            <Box
              component="form"
              noValidate
              onSubmit={formSubmissionHandler}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={errors.name}
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    // autoComplete="given-name"
                    name="name"
                    required
                    fullWidth
                    id="firstName"
                    value={inputs.name}
                    label={
                      errors.name
                        ? "Please enter your First Name"
                        : "First Name"
                    }
                  // autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.surname}
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    fullWidth
                    id="lastName"
                    label={
                      errors.name
                        ? "Please enter your Last Name"
                        : "Last Name"
                    }
                    name="surname"
                    value={inputs.surname}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.idNumber}
                    fullWidth
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    id="id"
                    name="idNumber"
                    type="text"
                    value={inputs.idNumber}
                    label={
                      errors.idNumber
                        ? "Invalid SA ID number"
                        : "South Africa ID Number"
                    }
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.email}
                    fullWidth
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    id="email"
                    label="Email Address"
                    name="email"
                    value={inputs.email}
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.password}
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    fullWidth
                    name="password"
                    label={
                      errors.password ? "special character ex #!@$%^&" : "Password"
                    }
                    type="password"
                    id="password"
                    value={inputs.password}
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.brokerName}
                    fullWidth
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    id="brokerName"
                    name="brokerName"
                    type="text"
                    value={inputs.brokerName}
                    label={
                      errors.brokerName
                        ? "Enter your Intermediary name"
                        : "Intermediary name"
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.fspNumber}
                    fullWidth
                    onChange={InputChangeHandler}
                    onBlur={InputChangeHandler}
                    id="fspNumber"
                    name="fspNumber"
                    type="text"
                    value={inputs.fspNumber}
                    label={
                      errors.fspNumber
                        ? "Enter your Intermediary number"
                        : "Intermediary FSP Number"
                    }
                    autoComplete="brokeragefspnumber"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-error-label">Intermediary Type</InputLabel>
                    <Select
                      error={errors.brokerType}
                      onChange={InputChangeHandler}
                      name="brokerType"
                      id="brokerType"
                      value={inputs.brokerType}
                    >
                      <MenuItem value={0}>Catholic Church Champion</MenuItem>
                      <MenuItem value={1}>General Broker</MenuItem>
                      <MenuItem value={2}>Cartrack</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginBottom={"40px"}>
                <SharedButton
                  handleOnClick={formSubmissionHandler}
                  variant="contained"
                  text={`Register`}
                ></SharedButton>
              </Box>
              <Loader state={loader} ></Loader>
            </Box>
          </Box>
        </Container>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Registration Successful
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              We are currently reviewing your registration eligibility. Kindly anticipate a maximum of 24 hours for us to process and confirm your registration. Be sure to monitor your inbox for the forthcoming registration approval notification. We look forward to welcoming you soon!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default Register;
