import logo from "./logo.svg";
import "./App.scss";
import React ,{useEffect} from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Link, Outlet } from "react-router-dom";

function App() {
  document.title = "Triarc | Intermediary Portal";
  useEffect(() => {
      
  }, [])
  
  return (
    <>
      <Header />
      <div className="content-container">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default App;
