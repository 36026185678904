import React, { useContext, useState } from 'react';
import { Container } from '@mui/material';
import AuthContext from "../../components/Context/AuthContext";

const Forms = () => {
    const [loader, setLoader] = useState(false);
    const authContext = useContext(AuthContext);

    return (
        <>
            {authContext.inputs.brokerType == 2 &&
                <>
                    <iframe
                        src="https://forms.triarc.co.za"
                        width="100%"
                        height="800px"
                        allowfullscreen
                        style={{ border: 0 }}
                    ></iframe>
                </>
            }

        </>
    )
}

export default Forms