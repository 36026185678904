import React, { useContext, useState, useEffect } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Register from "../src/components/Register/Register";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import MuiAlert from "@mui/material/Alert";
import AuthContext from "./components/Context/AuthContext";
import { Snackbar } from "@material-ui/core";
import Landing from "./components/Landing/Landing";
import DashboardAppPage from "./Pages/DashboardAppPage";
import DashboardLayout from "../src/layouts/dashboard/DashboardLayout";
import UserPage from './Pages/UserPage';
import BlogPage from './Pages/BlogPage';
import ProductsPage from './Pages/ProductsPage';
import { HelmetProvider } from "react-helmet-async";
import Quiz from "./Pages/Quiz/Quiz";
import Downloads from "./Pages/Downloads/Downloads";
import Forms from "./Pages/Forms/Forms";
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import Analytics from "./Analytics/Analytics";

const Navigation = () => {

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.isLoggedIn;

  if (localStorage.getItem("token") != null) {
    let journey = localStorage.getItem("journey");
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Analytics>
            <Routes>
              <Route path="/" element={<App />}>
                <Route path="*" element={<Landing />} />
                <Route path="/" element={<Landing />} />
                <Route path="register" element={<Register showSnackbar={handleSnackbarOpen} />} />
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password/:tokens" element={<ResetPassword />} />
                {isLoggedIn && <Route path="home" element={<Home showSnackbar={handleSnackbarOpen} />} />}
              </Route>

              {isLoggedIn &&
                <Route path="/dashboard" element={<DashboardLayout />}>
                  <Route index element={<Navigate to="/dashboard/app" />} />
                  <Route path="app" element={<DashboardAppPage />} />
                  <Route path="user" element={<UserPage />} />
                  <Route path="products" element={<ProductsPage />} />
                  <Route path="downloads" element={<Downloads />} />
                  <Route path="forms" element={<Forms />} />
                  <Route path="quiz" element={<BlogPage />} />
                  <Route path="quiz/:quizId" element={<Quiz />} />

                </Route>
              }
            </Routes>
          </Analytics>
        </BrowserRouter>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4500}
          onClose={handleSnackbarClose}

          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </HelmetProvider>
    </>
  );
};

export default Navigation;
