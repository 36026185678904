import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core/styles";
import theme from '../theme';

const useStyles = makeStyles({
    primary: {
        background: theme.colors.secondary,
        border: 0,
        borderRadius: 10,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: 48,
        padding: "0 30px",
        "&:hover": {
            background: theme.colors.secondaryHover
        },
        width: '200px'
    }
});

const SharedButton = ({ text, handleOnClick,type, disabled,color }) => {

    const style = useStyles();

    return (
        <>
            <Button
                disabled={disabled}
                className={style.primary}
                onClick={handleOnClick}
                variant="contained"
                color={color}
            >{text}</Button>

        </>
    )
}

export default SharedButton