import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoginIcon from "@mui/icons-material/Login";
import Loader from "../Shared/Loader";
import AuthContext from "../Context/AuthContext";
import theme from "../Shared/theme";
import ForgotPasswordLink from "../Shared/ForgotPasswordLink/ForgotPasswordLink";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [errors, setError] = useState({
    email: false,
    password: false,
  });

  const [valid, setValid] = useState({
    email: false,
    password: false,
  });

  const [success, setSuccess] = useState();
  const [failed, setFailed] = useState();
  const [loader, setLoader] = useState(false);

  const [successfulResponse, setSuccessfulResponse] = useState(false);
  const [failedResponse, setFailedResponse] = useState(false);

  useEffect(() => {
    console.log("success value : " + success);
  }, [success]);

  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  const authContext = useContext(AuthContext);

  // generic handler
  const InputChangeHandler = (event) => {
    const value = event.target.value.trim("");
    setInputs({
      ...inputs,
      [event.target.name]: value,
    });

    if (event.target.value.trim() === "") {
      setError({ ...errors, [event.target.name]: true });
      return;
    }

    setValid({ ...valid, [event.target.name]: true });
    setError({ ...errors, [event.target.name]: false });
  };

  const InputBlurHandler = (event) => {
    const value = event.target.value;
    setInputs({
      ...inputs,
      [event.target.name]: value,
    });

    if (event.target.value.trim() === "") {
      setError({ ...errors, [event.target.name]: true });
      return;
    }
    setValid({ ...valid, [event.target.name]: true });
    setError({ ...errors, [event.target.name]: false });
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    if (inputs.email === "") {
      setError((x) => ({ ...x, email: true }));
    }

    if (inputs.password === "") {
      setError((x) => ({ ...x, password: true }));
    }

    if (valid.email && valid.password) {
      sendPostRequest();
    }
  };

  const [open, setOpen] = React.useState(false);
  const axios = require("axios").default;

  const sendPostRequest = async (event) => {
    setLoader(true);
    try {
      const resp = await axios
        .post(process.env.REACT_APP_URL + "authentication/login", {
          email: inputs.email,
          password: inputs.password,
        })
        .catch(() => {
          setFailedResponse(true);
          setLoader(false);
        });

      if (resp.status == 200) {
        if (resp.data.token != null) {
          setSuccess(true);
          authContext.login(resp.data.token);
          routeChange("/dashboard"); //change
        }
      }
    } catch (err) {

    }
  };

  const journeyController = () => {
    let getGlobalState = localStorage.getItem("journey");
    let parser = JSON.parse(getGlobalState);

    if(parser != null){
      parser.forEach(element => {
      });
    }
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailedResponse(false);
    setOpen(false);
    setFailed(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: theme.colors.primary , ':hover': { backgroundColor: theme.colors.secondary }}}>
              <LoginIcon></LoginIcon>
            </Avatar>
            <Typography component="h1" variant="h5">
              Please enter your Login details
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={formSubmissionHandler}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.email}
                    fullWidth
                    onChange={InputChangeHandler}
                    onBlur={InputBlurHandler}
                    id="email"
                    label="Email Address"
                    name="email"
                    value={inputs.email}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.password}
                    onChange={InputChangeHandler}
                    onBlur={InputBlurHandler}
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={inputs.password}
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: theme.styling.borderRadius, borderRadius: theme.styling.borderRadius, bgcolor: theme.colors.primary , ':hover': { backgroundColor: theme.colors.secondary } , color : theme.colors.textColor }}
              >
                Login
              </Button>
              <ForgotPasswordLink />
              <Loader state={loader}></Loader>
              <Snackbar
                open={failed || successfulResponse || failedResponse}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={
                    successfulResponse
                      ? "success"
                      : "" || failed
                      ? "error"
                      : "" || failedResponse
                      ? "error"
                      : ""
                  }
                  sx={{ width: "100%" }}
                >
                  {successfulResponse && "User succesfully registered"}
                  {failed && "Please enter all fields"}
                  {failedResponse && "Incorrect email and password"}
                </Alert>
              </Snackbar>
              <Grid container justifyContent="flex-end"></Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Login;
