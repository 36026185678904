import React from 'react'
import styles from './ForgotPasswordLink.module.scss';
import { Link, useNavigate } from "react-router-dom";

const ForgotPasswordLink = () => {
  return (
    <>
      <div className={styles["Link-Container"]}>
        <Link to="/forgot-password" style={{ textDecoration: 'solid', color: 'gray', }} >Forgot your Password ?</Link>
      </div>
    </>
  )
}

export default ForgotPasswordLink