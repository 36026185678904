import React, { useState, useEffect } from "react";
import { URLS } from "../../components/Shared/Routes/Routes";
import jwt_decode from "jwt-decode";

const AuthContext = React.createContext({
  token: false,
  isLoggedIn: false,
  login: (token) => { },
  logout: () => { },
  customerDetails: async () => { },
  inputs: {
    name: "",
    surname: "",
    email: "",
    brokerName: "",
    fspNumber: "",
    brokerType: "",
    brokerCategory: ""
  }
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  const [loader, setLoader] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  useEffect(() => {
    isValidUser();
  });

  const axios = require("axios").default;

  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    brokerName: "",
    fspNumber: "",
    brokerType: ""
  });

  const loginHandler = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  const logoutHandler = () => {
    setToken(null);
    setUserIsLoggedIn(false);
    localStorage.removeItem("journey");
    localStorage.removeItem("token");
  };

  const isValidUser = () => {
    if (token != null) {
      const decoded = jwt_decode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decoded.exp;

      if (currentTime <= expirationTime) {
        setUserIsLoggedIn(true);
        console.log('Token is still valid.');
      } else {
        console.log('Token has expired.');
        logoutHandler();
      }
    }
  }

  const getCustomerDetails = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL + URLS.getCustomer, {
        headers: { "Authorization": `Bearer ${token}` }
      });

      let customerData = response.data;

      setInputs(prevInputs => ({ ...prevInputs, ...customerData }));

    } catch (error) {
      console.error("Error:", error);
      console.log(error);
    }
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    customerDetails: getCustomerDetails,
    inputs: inputs
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;