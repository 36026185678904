import { colors } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
  },
  colors: {
    primary: "#004280",
    primaryLight: "#c9a7be",
    secondary: "#ffb337",
    secondaryLight: "#ed8c8c",
    secondaryHover: "#f2ba61",
    textColor: "#ad4c18",
  },
  styling: {
    borderRadius: "10px"
  },
  tiles: {
    margin: "10px",
    color: "#ed8c8c",
    colorLight: "#50a0c7", 
    bulletSize: 10,
    unused: '#9E9E9E'

  },
  slider: {
    track: {
      radius: '3px',
      height: '10px',
    },
    thumb: {
      height: '40px',
      width: "40px",
      backgroundColor: "#fff",
      textColor: '#000000'
    },
    rail: {
      radius: '3px',
      height: '10px',
      backgroundColor: "rgb(205 205 205)",
      // backgroundColor: "#00000",
      // backgroundColor: "#008FD5"

    }


  }
});

export default theme; 
