import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const DownloadCards = ({url, heading, body, fileName}) => {

  const handleDownload = () => {
    // Create a new anchor element to initiate the download
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${fileName}.pdf`; // Set the custom file name here
    document.body.appendChild(downloadLink);
    // downloadLink.target = '_blank'; 
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  };

  return (
    <>
    <Card sx={{ width: 275, height: 200}}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{mb: 2}}>
            {heading}
        </Typography>
       
        <Typography variant="body2" >
         {body}
        </Typography>
      </CardContent>
      <CardActions>
        <Button target="_blank" href={url} onClick={handleDownload}>
                Click to download
            </Button>
      </CardActions>
    </Card>
    </>
  )
}

export default DownloadCards