import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Loader from "../../components/Shared/Loader";
import AuthContext from "../../components/Context/AuthContext";
import theme from "../../components/Shared/theme";
import { passwordRegex } from '../../components/Shared/Services/Regex/regex';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ResetPassword = (props) => {
  const [open, setOpen] = React.useState(false);
  const [formValid, setFormValid] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successfulResponse, setSuccessfulResponse] = useState(false);
  const [failedResponse, setFailedResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("We didn't get that please try again");
  const token = localStorage.getItem("token");
  let { tokens, email } = useParams();

  var [passwordToken,emailToken] = tokens.split("&");

  const authContext = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: ""
  });

  const [errors, setError] = useState({
    password: false,
    confirmPassword: false,
    passwordMatches: false
  });

  const [valid, setValid] = useState({
    password: false,
    confirmPassword: false,
    passwordMatches: false
  });

  let navigate = useNavigate();

  useEffect(() => {
    
  }, [])
  

  const routeChange = (path) => {
    navigate(path);
  };

  const checkIfPasswordMatches = (password, oldPassword) => {
    setError({ ...errors, confirmPassword: true })

    if (password == oldPassword) {
      console.log("passwords matches");
      setValid({ ...valid, passwordMatches: true, confirmPassword: true  });
      setError({ ...errors, confirmPassword: false });
      return;
    }
  }

  const passwordHandler = (event) => {
    setValid({ ...valid, passwordMatches: false, confirmPassword: false  });

    const value = event.target.value;

    setInputs({
      ...inputs,
      password: value,
    });

    if (event.target.value.trim() === "") {
      console.log("empty");
      setError({ ...errors, password: true });
      return;
    }

    setError({ ...errors, [event.target.name]: true })
    console.log("password is NOT valid");

    if (passwordRegex.test(event.target.value.trim())) {
      console.log("password is valid ");
      setError({ ...errors, password: false });
      setValid({ ...valid, password: true });
      return;
    }
    return;
  }

  const confirmPasswordHandler = (event) => {

    const confirmPassword = event.target.value;

    setInputs({
      ...inputs,
      confirmPassword: confirmPassword,
    });

    checkIfPasswordMatches(inputs.password, confirmPassword);
  }

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    if (inputs.password === "") {
      setError((x) => ({ ...x, password: true }));
    }

    if (inputs.confirmPassword === "") {
      setError((x) => ({ ...x, confirmPassword: true }));
    }

    console.log(valid.password,
      valid.confirmPassword,
      valid.passwordMatches);

    if (
      valid.password &&
      valid.confirmPassword &&
      valid.passwordMatches
    ) {
      setOpen(true);
      sendPostRequest();
    } else {
      setFailed(true);
    }
  };

  const axios = require("axios").default;

  const sendPostRequest = async (event) => {
    setLoader(true);
    const resp = await axios.post(
      process.env.REACT_APP_URL + "authentication/reset-password",
      {
        email: emailToken,
        token: passwordToken,
        NewPassword: inputs.password,
        NewPasswordconfirmation: inputs.confirmPassword,
      }
    ).catch((error) => {
      setFailedResponse(true);
      setLoader(false);
      setErrorMessage(error.response.data);
    });

    if (resp.status == 200) {
        setOpen(true);
        setLoader(false);
        setSuccessfulResponse(true);
        routeChange("/login");
    }
    return;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailedResponse(false);
    setOpen(false);
    setFailed(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: theme.colors.primary }}>
              <AppRegistrationIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={formSubmissionHandler}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                {
                  errors.password ?
                    <Grid item xs={12}>
                      <Stack spacing={2}>
                        <Item>Your password must be atleast 8 characters long
                          and contain atleast 1 uppercase letter, 1 number and 1 special character
                        </Item>
                      </Stack>
                    </Grid> : ""
                }
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.password}
                    onChange={passwordHandler}
                    onBlur={passwordHandler}
                    fullWidth
                    name="password"
                    label={
                      errors.password ? "special character ex #!@$%^&" : "Password"
                    }
                    type="password"
                    id="password"
                    value={inputs.password}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.confirmPassword}
                    onChange={confirmPasswordHandler}
                    onBlur={confirmPasswordHandler}
                    fullWidth
                    name="confirmPassword"
                    label={
                      errors.confirmPassword ? "Password do not match" : "Confirm Password"
                    }
                    type="password"
                    id="confirmPassword"
                    value={inputs.confirmPassword}
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: theme.styling.borderRadius, bgcolor: theme.colors.primary, ':hover': { backgroundColor: theme.colors.secondary }, color: theme.colors.textColor }}
              >
                Reset Password
              </Button>
              <Loader state={loader} ></Loader>
              <Snackbar
                open={failed || successfulResponse || failedResponse}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={successfulResponse ? "success" : "" || failed ? "error" : "" || failedResponse ? "error" : ""}
                  sx={{ width: "100%" }}
                >
                  {successfulResponse && "Password changes successfully"}
                  {failed && "Please enter all fields"}
                  {failedResponse && errorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default ResetPassword;
