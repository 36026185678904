import React, { useEffect } from 'react'
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const Analytics = (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: pathname, title: pathname});
    }, [pathname])

    return (
        <>
            {props.children}
        </>
    )
}

export default Analytics