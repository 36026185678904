import { Container, Typography, Stack, Button } from '@mui/material'
import React from 'react'
import theme from '../Shared/theme'
import SharedButton from '../Shared/SharedButton/SharedButton'
import { useNavigate } from "react-router-dom";

const Landing = () => {

    let navigate = useNavigate();

    const routeChange = (path) => {
        navigate(path);
    };


    const onHandleLogin = () => {
        routeChange("/login");
    }

    const onHandleRegister = () => {
        routeChange("/register");
    }

    return (
        <>
            <Container maxWidth='md'>
                <Stack alignItems={"center"} sx={{marginTop: {sm: 20, xs: 5}}}>
                        <Typography textAlign={"center"} variant='h2'>Financial advisor portal</Typography>

                    <Stack alignItems={"center"} marginTop={5} gap={3}>
                        <SharedButton
                            handleOnClick={onHandleLogin}
                            variant="contained"
                            text={`Login`}
                        ></SharedButton>
                        <SharedButton
                            handleOnClick={onHandleRegister}
                            variant="contained"
                            text={`Register`}
                        ></SharedButton>
                    </Stack>

                </Stack>
            </Container>
        </>
    )
}

export default Landing