import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Loader from "../../components/Shared/Loader";
import AuthContext from "../../components/Context/AuthContext";
import theme from "../../components/Shared/theme";
import { emailRegex } from '../../components/Shared/Services/Regex/regex';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ForgotPassword = () => {
  const [open, setOpen] = React.useState(false);
  const [formValid, setFormValid] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [successfulResponse, setSuccessfulResponse] = useState(false);
  const [failedResponse, setFailedResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("We didn't get that please try again");
  const token = localStorage.getItem("token");

  const authContext = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    email: ""
  });

  const [errors, setError] = useState({
    email: false
  });

  const [valid, setValid] = useState({
    email: false,
  });

  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  const sendForgotPassword = (event) => {
    setError({ ...errors, email: true });

    setInputs({
      ...inputs,
      email: event.target.value,
    });

    if (emailRegex.test(event.target.value.trim())) {
      setError({ ...errors, email: false });
      setValid({ ...valid, email: true });
      return;
    }

    setValid({ ...valid, email: false });

  }

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    if (inputs.email === "") {
      setError((x) => ({ ...x, password: true }));
    }

    if (valid.email) {

      setOpen(true);
      sendPostRequest();
    } else {
      setFailed(true);
    }
  };

  const axios = require("axios").default;

  const sendPostRequest = async (event) => {
    
    setLoader(true);
    console.log("email " , inputs.email);
    const resp = await axios.post(
      process.env.REACT_APP_URL + "authentication/forgot-password-link",
      {
        email: inputs.email
      }
    ).catch((error) => {
      console.log(error);
      setFailedResponse(true);
      setLoader(false);
      setErrorMessage(error.response.data);
    });

    console.log(resp);
    if (resp.status == 200) {
        setOpen(true);
        setLoader(false);
        setSuccessfulResponse(true);
    }
    return;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailedResponse(false);
    setOpen(false);
    setFailed(false);
  };

  const showPasswordSentMessage = () => {
    return (<>
      <p>Please check your inbox for a password reset link and click on the "reset password" button. Reminder to check junk folder if email is not received</p>
    </>)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: theme.colors.primary }}>
              <AppRegistrationIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={formSubmissionHandler}
              sx={{ mt: 3 }}
            >
              { !successfulResponse &&
              <>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Please enter your email address and we will send you a reset password link</h3>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={errors.email}
                    onChange={sendForgotPassword}
                    onBlur={sendForgotPassword}
                    fullWidth
                    name="email"
                    label={
                      errors.email ? "Please enter your email address" : "Email Address"
                    }
                    type="text"
                    id="text"
                    value={inputs.email}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: theme.styling.borderRadius, bgcolor: theme.colors.primary, ':hover': { backgroundColor: theme.colors.secondary }, color: theme.colors.textColor }}
              >
                Sign Up
              </Button>
              <Loader state={loader} ></Loader>
              <Snackbar
                open={failed || successfulResponse || failedResponse}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={successfulResponse ? "success" : "" || failed ? "error" : "" || failedResponse ? "error" : ""}
                  sx={{ width: "100%" }}
                >
                  {successfulResponse && "User succesfully registered"}
                  {failed && "Please enter all fields"}
                  {failedResponse && errorMessage}
                </Alert>
              </Snackbar>
              </> 
              }

              {successfulResponse && 
              <>
              {showPasswordSentMessage()}
              </>}
              
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default ForgotPassword;
