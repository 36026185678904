import React,{ useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import styles from "./loader.module.scss";
import theme from '../Shared/theme';

const Loader = (props) => {

  return (
    <>
      <Backdrop
        sx={{ color: theme.colors.primary, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.state}
      >
        <CircularProgress className={styles["overlay"]} />
      </Backdrop>
    </>
  );
};

export default Loader;
