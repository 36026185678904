import React, { useState, useContext } from "react";
import styles from "./Header.module.scss";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
// import ChurchIcon from '@mui/icons-material/Church';
import logo from "../../assets/img/Triarc-Dark-Logo.svg";
import theme from '../Shared/theme';

const Header = () => {
  const [isActive, setActive] = useState(false);

  const openMenu = () => {
    setActive(!isActive);
  };

  const authContext = useContext(AuthContext);

  const userIsLoggedIn = authContext.isLoggedIn;

  let navigate = useNavigate();

  const routeChange = (path) => {
    setActive(false);
    navigate(path);
  };

  return (
    <>
      <div className={styles["header-container"]}>

        <div className={styles["header-bar"]}>
          <div className={styles["header-logo-container"]}>
            <img src={logo} onClick={() => { routeChange('/') }} />
            {/* <ChurchIcon sx={{fontSize: 40}} onClick={() => { routeChange('/') }} />  */}
            <div className={styles["hamburger"]} onClick={openMenu}>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
            </div>
          </div>

          <div className={styles["header-content-container"]}>
            <div className={isActive ? styles["header-content-active"] : styles["header-content"]}>
            <div className={isActive ? styles["header-content-active"] : styles["header-content"]}>
                {/* <div className={isActive ? styles["header-content-active"] : styles["header-content"]} onClick={() => { routeChange('/how-it-works'); setActive(false); }}>How it Works</div> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Header;
